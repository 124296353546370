<template>

<div class="loadFail table-hook fadeInRight">
    <el-table :data="tableData">
        <el-table-column label="分销表编号" prop="id" show-overflow-tooltip></el-table-column>
        <el-table-column label="电话" prop="phone" show-overflow-tooltip></el-table-column>
        <el-table-column label="ICCID号" prop="iccidMark" show-overflow-tooltip></el-table-column>
        <el-table-column label="错误描述" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" prop="opt">
            <template slot-scope="scope">
                <a href="javascript:void(0)" @click="detail(scope.row.id)">详情</a>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 50,100,1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div> 

    <el-dialog title="分销卡失败详情" :visible.sync="dialogVisible">
        <div>
            <p class="dia-title">分销表编号:</p>
            <el-input v-model="importCardId" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">电话:</p>
            <el-input v-model="phone" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">ICCID号:</p>
            <el-input v-model="iccidMark" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">错误描述:</p>
            <el-input v-model="remark" readonly></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
    </el-dialog>


</div>

</template>

<script type="text/ecmascript-6">

import {distributioncardfail,findById} from '@/api/cardManagement/distributionCard.js'

export default {
    data(){
        return {
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogVisible:false,
            importCardId:'',
            phone:'',
            iccidMark:'',
            remark:''
        }
    },
    mounted(){
        this._distributioncardfail()
    },
    methods:{
        detail(id){
            this.dialogVisible = true
            let params = {id}
            findById(params).then((res)=>{
                let data = res.entity
                this.importCardId = data.id
                this.phone = data.phone
                this.iccidMark = data.iccidMark
                this.remark = data.remark
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._distributioncardfail()  
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._distributioncardfail()
        },
        _distributioncardfail(){
            let params = this.$route.query
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            distributioncardfail(params).then((res) =>{
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
<style>
     .loadFail .dia-title{margin-top:10px;margin-bottom:6px;}
</style>
